import React, { useEffect, useState } from 'react';
import {
  SharedElementOverlay,
  SharedElementOverlayProps,
} from '@/components/shared-element-overlay';
import { InputModal } from '@/components/input-modal';
import { centered, useSharedElement } from '@/components/use-shared-element';
import { useDeactivateUserApi } from '@/features/teammates/useDeactivateUserApi';

interface DeleteModalProps extends SharedElementOverlayProps {
  hide: () => void;
  email: string;
  refreshUserList: () => void;
}

export function DeleteModal(props: DeleteModalProps) {
  const { hide, email, refreshUserList, ...rest } = props;
  const { loading, error, deactivateUser } = useDeactivateUserApi();
  const description = `Type the email of the user you'd like to delete.`;
  const [desc, setDesc] = useState(description);
  useEffect(() => {
    if (error) {
      setDesc(`oops.. something went wrong`);
    }
  }, [error]);
  const onConfirm = async (value) => {
    if (loading) return;
    if (value === email) {
      await deactivateUser(email);
      setDesc(description);
      refreshUserList();
      hide();
    } else {
      setDesc(`Emails dont match.`);
    }
  };

  return (
    <SharedElementOverlay {...rest}>
      <InputModal
        title="Are You Sure ?"
        description={desc}
        yesCaption="Delete"
        noCaption="Discard"
        initialValue=""
        onConfirm={onConfirm}
        onDiscard={() => {
          setDesc(description);
          hide();
        }}
      />
    </SharedElementOverlay>
  );
}

export function useDeactivateUserModalProps() {
  const {
    props: deleteModalProps,
    show: showDeleteModal,
    hide: hideDeleteModal,
    fromRef: deleteRef,
  } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `#dedede`,
        opacity: 0,
      },
      extraTo: {
        background: `white`,
        opacity: 1,
      },
    },
    undefined,
    () => centered(23, 42),
  );
  return {
    deleteModalProps,
    showDeleteModal,
    hideDeleteModal,
    deleteRef,
  };
}
