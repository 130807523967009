import React, { useState } from 'react';
import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';
import {
  SharedElementOverlay,
  SharedElementOverlayProps,
} from '@/components/shared-element-overlay';
import { centered, useSharedElement } from '@/components/use-shared-element';
import { useDeactivateUserApi } from '@/features/teammates/useDeactivateUserApi';
import { RolesSelect } from '@/features/teammates/RoleSelect';
import { BigButton } from '@/components/big-button';
import { Role } from '@/utils/definitions';

interface Props extends SharedElementOverlayProps {
  role: Role;
  refreshUserList: () => void;
  email: string;
  hide: () => void;
}

export function EditRoleModal(props: Props) {
  const { hide, email, role: currentRole, refreshUserList, ...rest } = props;
  const { useUpdateUserRole, loading } = useDeactivateUserApi();
  const [role, setRole] = useState(currentRole);
  const onConfirm = async () => {
    await useUpdateUserRole(role, email);
    refreshUserList();
    hide();
  };

  return (
    <SharedElementOverlay {...rest} heightMultiplier={1.5}>
      <SelectModal>
        <p>Please select role</p>
        <CloseBtn onClick={hide} data-testid="close-edit-role">
          <AiOutlineClose size={15} />
        </CloseBtn>
        <RolesSelect
          menuPlacement="bottom"
          currentRole={role}
          setIsSelectOpen={() => 0}
          onChange={(x) => {
            setRole(x?.value);
          }}
        />
        <div style={{ height: `80px` }} />
        <BigButton
          zIdx={0}
          type="button"
          onClick={onConfirm}
          border="2px solid black"
          noTransform
          size="large"
          fillWidth
        >
          {loading ? `...` : `Submit`}
        </BigButton>
      </SelectModal>
    </SharedElementOverlay>
  );
}

const SelectModal = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 2rem;

  p:first-child {
    font-size: 17px;
    font-weight: 800;
    user-select: none;
    margin-bottom: 2rem;
  }

  z-index: 0;

  button {
    margin: 3rem 0;
  }
`;

const CloseBtn = styled.button`
  position: absolute;
  right: 20px;
  top: 0;
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  &:active {
    cursor: default;
    opacity: 1;
  }
`;

export function useEditUserRoleModalProps() {
  const {
    props: editRoleModalProps,
    show: showEditRoleModal,
    hide: hideEditModal,
    fromRef: editModalRef,
  } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `#dedede`,
        opacity: 0,
      },
      extraTo: {
        background: `white`,
        opacity: 1,
      },
    },
    undefined,
    () => centered(23, 42),
  );
  return {
    editRoleModalProps,
    showEditRoleModal,
    hideEditModal,
    editModalRef,
  };
}
