import { CachePolicies, useFetch } from 'use-http';
import { routes } from '@/webapi/routes';
import { Role } from '@/utils/definitions';

export function useDeactivateUserApi() {
  const { post, error, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: false,
    },
  }));

  async function deactivateUser(email: string) {
    await post(routes.deactivateUser(), {
      email,
    });
  }

  async function useUpdateUserRole(role: Role, email: string) {
    await post(routes.updateUserRole(), {
      email,
      role,
    });
  }

  return {
    error,
    loading,
    deactivateUser,
    useUpdateUserRole,
  };
}
