import React, { FC } from 'react';
import styled from 'styled-components';
import { UserProps } from '@/features/teammates/types';
import { Avatar } from '@/components/avatar';
import { firstLetters } from '@/components/header';
import { Badge } from '@/features/teammates/Badge';
import { AccountContextProps } from '@/features/account-context';
import { EdirPopover } from '@/features/teammates/EditPopover';

const Accronims = styled.p`
  font-size: 1.5rem;
  letter-spacing: 1.2px;
  font-weight: 500;
`;
const UserWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 2rem;

  section {
    min-width: 320px;
    width: 65%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    div:first-child {
      display: flex;
      align-items: center;
    }
  }
`;

const UserDetailsStyled = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    font-size: 14px;
    line-height: 17px;
    text-align: left;
  }

  div:first-child {
    font-weight: 800;
  }
`;

const UserDetails: FC<{ user: UserProps }> = ({ user }) => (
  <UserDetailsStyled>
    <div>
      {user?.firstName} {user?.lastName}
    </div>
    <div>{user.email}</div>
  </UserDetailsStyled>
);

function param(user: UserProps) {
  return {
    account: {
      store: {
        firstName: user?.firstName,
        lastName: user?.lastName,
      },
    },
  } as AccountContextProps;
}

export const User: FC<{
  user: UserProps;
  refreshUserList: () => void;
}> = ({ user, refreshUserList }) => (
  <UserWrapper>
    <section>
      <div>
        <Avatar size="4.8rem">
          <Accronims>{firstLetters(param(user))}</Accronims>
        </Avatar>
        <UserDetails user={user} />
      </div>
      <Badge role={user.role} />
    </section>
    <EdirPopover
      role={user.role}
      email={user.email}
      refreshUserList={refreshUserList}
    />
  </UserWrapper>
);
