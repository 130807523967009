import React, { FC } from 'react';
import styled from 'styled-components';
import { Header } from '@/components/header';
import { usePagination } from '@/webapi/use-pagination';
import { useListUsers } from '@/features/teammates/useListUsers';
import { UsersResponse } from '@/features/teammates/types';
import { AddUserPopover } from './AddUserPopOver';
import { User } from '@/features/teammates/User';
import { BackgroundGrey } from '@/components/global-css';
import { Pagination } from '@/components/pagination';

const Container = styled.div`
  margin: 2rem auto 5rem auto;
  max-width: 900px;
  min-width: 700px;
  border-radius: 10px;
  border: 1px solid #f1f3f4;
  background-color: white;
  box-shadow: 0 1px 4px 0 rgba(68, 72, 80, 0.08);
  text-align: center;
  padding: 3rem;

  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const Wrapper = styled.div`
  font-family: Inter, serif;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 102px 50px 80px 1fr;
  padding-bottom: 2rem;

  h1 {
    user-select: none;
    text-align: center;
    margin-top: 20px;
  }
`;

function UsersList({
  users,
  refreshUserList,
}: {
  users: any;
  refreshUserList: () => void;
}) {
  return users ? (
    <div>
      {(users as Array<any>)?.map((x) => (
        <User key={x.email} user={x} refreshUserList={refreshUserList} />
      ))}
    </div>
  ) : null;
}

export const Teammates: FC = () => {
  const { listUsers } = useListUsers();
  const pageSize = 10;
  const { total, current, loading, page, maxPages, setPage, refreshList } =
    usePagination(listUsers, pageSize);
  const resp = current as UsersResponse;
  return (
    <Wrapper>
      <Header />
      <div />
      <h1 data-testid="main-header">
        {total ? `Teammates (${total})` : `Teammates `}
      </h1>
      <Container>
        <UsersList users={resp?.users} refreshUserList={refreshList} />
        <AddUserPopover refreshList={refreshList} />
      </Container>
      <Pagination
        value={page + 1}
        pageCount={maxPages}
        pageSize={pageSize}
        total={total}
        loading={loading}
        onPageChanged={(page) => setPage(page - 1)}
        label="users"
      />
      <BackgroundGrey />
    </Wrapper>
  );
};
