import { ArrowContainer, Popover } from 'react-tiny-popover';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBin7Line } from 'react-icons/ri';
import {
  DeleteModal,
  useDeactivateUserModalProps,
} from './DeactivateUserModal';
import {
  EditRoleModal,
  useEditUserRoleModalProps,
} from '@/features/teammates/EditUserRoleModal';
import { Role } from '@/utils/definitions';

export function EdirPopover({
  email,
  role,
  refreshUserList,
}: {
  email: string;
  role: Role;
  refreshUserList: () => void;
}) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { hideDeleteModal, showDeleteModal, deleteModalProps, deleteRef } =
    useDeactivateUserModalProps();

  const { editRoleModalProps, showEditRoleModal, hideEditModal, editModalRef } =
    useEditUserRoleModalProps();
  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={[`right`]}
      padding={10}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="white"
          arrowSize={10}
          arrowStyle={{ opacity: 0.7 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <PopoverContentWrapper>
            <button type="button" onClick={showEditRoleModal}>
              <FiEdit2 size={15} /> Edit Role
            </button>
            <button type="button" onClick={showDeleteModal}>
              <RiDeleteBin7Line size={15} />
              Delete
            </button>
          </PopoverContentWrapper>
        </ArrowContainer>
      )}
    >
      <EditBtnContainer>
        <DeleteModal
          {...deleteModalProps}
          hide={hideDeleteModal}
          email={email}
          refreshUserList={refreshUserList}
        />
        <EditRoleModal
          {...editRoleModalProps}
          hide={hideEditModal}
          role={role}
          email={email}
          refreshUserList={refreshUserList}
        />
        <div ref={deleteRef} />
        <div ref={editModalRef} />
        <StyledIconBtn
          data-testid={`edit-${email}`}
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          <FiEdit2 size={15} />
        </StyledIconBtn>
      </EditBtnContainer>
    </Popover>
  );
}

const EditBtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PopoverContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #f1f3f4;
  box-shadow: 0 1px 4px 0 rgba(68, 72, 80, 0.08);

  button {
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }

    &:active {
      cursor: default;
      opacity: 1;
    }

    display: flex;

    svg {
      margin-right: 10px;
    }

    background: none;
    border: none;
    border-bottom: 1px solid #f1f3f4;

    padding: 15px 25px;
  }

  button:last-child {
    border-bottom: none;
  }
`;
const StyledIconBtn = styled.button`
  border-radius: 50%;
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  &:active {
    cursor: default;
    opacity: 1;
  }
`;
